import { render, staticRenderFns } from "./purchase.vue?vue&type=template&id=876d30e4&scoped=true"
import script from "./purchase.vue?vue&type=script&lang=js"
export * from "./purchase.vue?vue&type=script&lang=js"
import style0 from "./purchase.vue?vue&type=style&index=0&id=876d30e4&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "876d30e4",
  null
  
)

export default component.exports